import { useGrowthBook } from '@growthbook/growthbook-react';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useParams } from 'react-router-dom';

import env from '../../../env/env';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { SHOW_CART } from '../../../utils/growthbookFeatures';
import useMobileMediaQuery from '../../hooks/useMobileMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { AddProductWidget } from '../AddProductWidget/AddProductWidget';
import AuctionDetail from '../Auction/AuctionDetail';
import { CheckoutWidget } from '../CheckoutWidget/CheckoutWidget';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import Panel from '../Panel/Panel';
import ProductPrice from '../ProductPrice/ProductPrice';
import { ProductTagTypeEnum } from '../ProductTag/ProductTag.types';
import { ResultTime } from '../ResultTime/ResultTime';
import {
  GOLPOINTS_LOGO,
  MAX_QUANTITY_SELECTED,
  MIN_QUANTITY_SELECTED,
  PRODUCT_DETAIL_PRICE,
} from './ProductDetail.constants';
import { IProductDetailProps } from './ProductDetail.types';

const decreaseIcon = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/product/cart/decrease-icon.svg`;
const increaseIcon = `${env.REACT_APP_STATIC_BASE_URL}marketplace/img/commons/product/cart/increase-icon.svg`;

export const ProductDetail = ({
  productDetailResponse,
  selectedQuantity,
  setSelectedQuantity,
  validations,
  tags,
}: IProductDetailProps) => {
  const growthbook = useGrowthBook();
  const { productId } = useParams();
  const isTablet = useTabletMediaQuery();
  const isMobile = useMobileMediaQuery();

  const {
    awayClubTime,
    awayGoalTime,
    homeClubTime,
    homeGoalTime,
    isUninitialized,
    isLoading,
    player,
    productEvent,
    product,
    priceValue,
    currency,
    setErrorCode,
    isBuyAllowed,
    setShowAlert,
    productIsHolded,
    collectibles,
    isProductAuction,
  } = productDetailResponse || {};

  const handleAddQuantity = () => {
    if (
      MAX_QUANTITY_SELECTED === selectedQuantity ||
      (collectibles && collectibles.on_sale <= selectedQuantity)
    ) {
      return;
    }
    setSelectedQuantity((prevState: number) => prevState + 1);
  };

  const handleDecreaseQuantity = () => {
    if (MIN_QUANTITY_SELECTED === selectedQuantity) {
      return;
    }
    setSelectedQuantity((prevState: number) => prevState - 1);
  };

  const isProductAvailableToBuy =
    !productIsHolded &&
    collectibles &&
    collectibles.on_sale > 0 &&
    !isProductAuction;

  return (
    <Panel>
      <Stack
        gap={SpaceSizes.xs}
        p={isMobile ? SpaceSizes.xxs : SpaceSizes.md}
        width="100%"
      >
        <ResultTime
          awayClub={awayClubTime}
          awayGoal={awayGoalTime}
          homeClub={homeClubTime}
          homeGoal={homeGoalTime}
          isGol={product?.product_type === 'GOAL'}
          isLoading={isUninitialized || isLoading}
          player={player}
          productEvent={productEvent}
          tags={tags}
        />
        {isProductAuction && <AuctionDetail productId={productId} />}
        {isProductAvailableToBuy && (
          <Stack flexDirection="row" justifyContent="space-between">
            <ProductPrice
              height={GOLPOINTS_LOGO}
              size={isTablet ? 'medium' : 'large'}
              sx={{ fontSize: PRODUCT_DETAIL_PRICE }}
              value={priceValue}
              width={GOLPOINTS_LOGO}
              showEuro
            />
            {!tags.includes(ProductTagTypeEnum.DIRECT_SALE) && (
              <Stack
                alignItems="center"
                flexDirection="row"
                gap={SpaceSizes.md}
              >
                <Stack
                  height={{
                    xs: SpaceSizes.mdPlus,
                    md: SpaceSizes.lg,
                  }}
                  style={{ cursor: 'pointer' }}
                  width={{
                    xs: SpaceSizes.mdPlus,
                    md: SpaceSizes.lg,
                  }}
                  onClick={handleDecreaseQuantity}
                >
                  <ImageFallback
                    alt="button-decrease-quantity"
                    fallback={decreaseIcon}
                    height="100%"
                    src={decreaseIcon}
                    style={{
                      opacity:
                        MIN_QUANTITY_SELECTED === selectedQuantity ? 0.4 : 1,
                    }}
                    width="100%"
                  />
                </Stack>

                <Typography variant="h6">{selectedQuantity}</Typography>

                <Stack
                  height={{
                    xs: SpaceSizes.mdPlus,
                    md: SpaceSizes.lg,
                  }}
                  style={{ cursor: 'pointer' }}
                  width={{
                    xs: SpaceSizes.mdPlus,
                    md: SpaceSizes.lg,
                  }}
                  onClick={handleAddQuantity}
                >
                  <ImageFallback
                    alt="button-increase-quantity"
                    fallback={increaseIcon}
                    height="100%"
                    src={increaseIcon}
                    style={{
                      opacity:
                        MAX_QUANTITY_SELECTED === selectedQuantity ||
                        collectibles.on_sale <= selectedQuantity
                          ? 0.4
                          : 1,
                    }}
                    width="100%"
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
        {isProductAvailableToBuy && (
          <Stack flexDirection="row" gap={SpaceSizes.md}>
            {!tags.includes(ProductTagTypeEnum.DIRECT_SALE) &&
              growthbook?.isOn(SHOW_CART) && (
                <AddProductWidget
                  productId={productId}
                  selectedQuantity={selectedQuantity}
                  setErrorCode={setErrorCode}
                  setShowAlert={setShowAlert}
                />
              )}
            <CheckoutWidget
              disableButton={!isBuyAllowed}
              price={{ priceValue, currency }}
              product={product}
              productId={productId}
              selectedQuantity={selectedQuantity}
              setErrorCode={setErrorCode}
              setShowAlert={setShowAlert}
              showPrice={false}
              validations={validations}
            />
          </Stack>
        )}
      </Stack>
    </Panel>
  );
};
