import PlaceIcon from '@mui/icons-material/Place';
import { Box, Stack, Typography } from '@mui/material';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import { formatUTCtoLocalTime } from '../../../utils/date';
import { geti18nResolvedLanguage } from '../../i18n';
import { CustomTable } from '../CustomTable/CustomTable';
import { MatchResult } from '../MatchResult/MatchResult';
import { TAlignTypes } from '../Row/Row.types';
import { ProductStatsTableWidgetSkeleton } from './ProductStatsTableWidget.skeleton';
import { IProductStatsTableWidgetProps } from './ProductStatsTableWidget.types';

export const ProductStatsTableWidget = ({
  awayClub,
  homeClub,
  match,
  productEvent,
  player,
  tournament,
  competition,
  stage,
  goalClubId,
  isLoading,
  productType,
}: IProductStatsTableWidgetProps) => {
  const { t } = useTranslation();
  const matchResult = useMemo(
    () =>
      `${match?.home.code}  ${match?.home.score} - ${match?.away.score} ${match?.away.code}`,
    [match],
  );

  const renderSimpleRow = useCallback(
    (
      firstColumnTag: string,
      secondColumnValue: string | undefined | ReactElement | Date,
      formatDate?: boolean,
    ) => {
      return {
        rowsContent: [
          {
            content: t(firstColumnTag),
          },
          {
            content: formatDate
              ? formatUTCtoLocalTime(
                  geti18nResolvedLanguage(),
                  secondColumnValue as Date,
                  undefined,
                  undefined,
                  false,
                )
              : (secondColumnValue as ReactElement),
            align: 'right' as TAlignTypes,
          },
        ],
      };
    },
    [t],
  );

  const renderMatchRow = useCallback(() => {
    return {
      rowsContent: [
        {
          content:
            awayClub && homeClub ? (
              <Stack alignItems="center" spacing={SpaceSizes.sm}>
                <Typography fontWeight="bold" variant="h6">
                  {productType === 'GOAL'
                    ? t('pages.product.productSection.goalResult')
                    : t('pages.product.productSection.matchInfo')}
                </Typography>

                <MatchResult
                  away={awayClub}
                  awayScore={
                    productType === 'GOAL'
                      ? productEvent.event_data.away_partial_score ?? 0
                      : 0
                  }
                  golClubId={goalClubId ?? player?.team_id}
                  home={homeClub}
                  homeScore={
                    productType === 'GOAL'
                      ? productEvent.event_data.home_partial_score ?? 0
                      : 0
                  }
                  iconsSize="55"
                  showScore={productType === 'GOAL'}
                  sx={{ width: '100%' }}
                  textVariant="h4"
                  showNames
                />
              </Stack>
            ) : (
              <Box />
            ),
          colSpan: 2,
        },
      ],
    };
  }, [
    awayClub,
    homeClub,
    player?.team_id,
    t,
    goalClubId,
    productEvent,
    productType,
  ]);

  const rows = useMemo(() => {
    const result = [
      // renderGolRow(),
      renderMatchRow(),
      renderSimpleRow(
        'pages.product.productSection.labels.matchStadium',
        <Box alignItems="center" display="flex" justifyContent="end">
          <PlaceIcon color="inherit" fontSize="small" />
          <Typography pl={SpaceSizes.sm}>
            {match?.stadium?.stadium_name}
          </Typography>
        </Box>,
      ),
      renderSimpleRow(
        'pages.product.productSection.labels.tournament',
        tournament?.commercial_name,
      ),
      renderSimpleRow(
        'pages.product.productSection.labels.competition',
        `${competition?.name} ${tournament?.season}`,
      ),
      renderSimpleRow(
        'pages.product.productSection.labels.matchStageNumber',
        match?.week || stage?.name,
      ),
      renderSimpleRow(
        'pages.product.productSection.labels.matchDate',
        match?.dt,
        true,
      ),
    ];

    if (!match?.is_live) {
      result.splice(
        result.length - 1,
        0,
        renderSimpleRow(
          'pages.product.productSection.labels.matchResult',
          matchResult,
        ),
      );
    }

    return result;
  }, [
    renderMatchRow,
    renderSimpleRow,
    match?.stadium?.stadium_name,
    match?.week,
    match?.dt,
    match?.is_live,
    tournament?.commercial_name,
    tournament?.season,
    competition?.name,
    stage?.name,
    matchResult,
  ]);

  return (
    <Stack padding={0}>
      {isLoading ? (
        <ProductStatsTableWidgetSkeleton />
      ) : (
        <CustomTable rows={rows} />
      )}
    </Stack>
  );
};
