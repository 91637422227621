import { useGrowthBook } from '@growthbook/growthbook-react';
import { Box, Grid, Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import env from '../../../env/env';
import {
  selectBallName,
  selectCurrentGol,
  selectProduct3dCertificateMultimedia,
  selectProduct3dMultimedia,
  selectProductCarrouselMultimedia,
  selectProductChainOfCustodyMultimedia,
  selectProductContent,
  selectProductContract,
  selectProductGoalVideoMultimedia,
  selectProductGolClubId,
  selectProductIsAuction,
  selectProductIsWinner,
  selectProductMatch,
  selectProductMatchAwayClub,
  selectProductMatchCompetition,
  selectProductMatchHomeClub,
  selectProductMatchStage,
  selectProductMatchTournament,
  selectProductPlayer,
  selectProductPlayerMultimedia,
  selectProductTacticMultimedia,
  selectProductTags,
} from '../../../features/products/Product.selectors';
import { IProduct } from '../../../features/products/Product.types';
import { useGetPolygonscanLinkQuery } from '../../../features/products/Products.api';
import {
  selectIsUserLoggedIn,
  selectIsUserNeedsPhoneValidation,
  selectUserId,
} from '../../../features/session/Session.selector';
import { useSendStickersMutation } from '../../../features/users/Users.api';
import SpaceSizes from '../../../theme/foundations/spacing/SpaceSizes';
import {
  SPONSOR_USERS,
  WEB3_FEATURES,
} from '../../../utils/growthbookFeatures';
import { useAppSelector } from '../../hooks';
import useBigDesktopMediaQuery from '../../hooks/useBigDesktopMediaQuery';
import useTabletMediaQuery from '../../hooks/useTabletMediaQuery';
import { AlertCard } from '../AlertCard/AlertCard';
import { AlertSeverity } from '../AlertCard/AlertSeverity';
import AuctionDetail from '../Auction/AuctionDetail';
import BookmarkAndShare from '../BookmarkAndShare/BookmarkAndShare';
import CollectableAdditionalInfo from '../CollectableAdditionalInfo/CollectableAdditionalInfo';
import { CollectableMainWidget } from '../CollectableMainWidget/CollectableMainWidget';
import { GolBallQuantity } from '../GolBallQuantity/GolBallQuantity';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import Panel from '../Panel/Panel';
import { PhoneVerificationDialog } from '../PhoneVerificationDialog/PhoneVerificationDialog';
import { CertificatesTabs } from '../ProductCertificatesTabsWidget/CertificatesTabs';
import { ProductContentWidget } from '../ProductContentWidget/ProductContentWidget';
import { ProductMediaWidget } from '../ProductMediaWidget/ProductMediaWidget';
import { ProductStatsTableWidget } from '../ProductStatsTableWidget/ProductStatsTableWidget';
import { ResultTime } from '../ResultTime/ResultTime';
import TriviaDetails from '../TriviaDetails/TriviaDetails';
import UserActions from '../UserActions/UserActions';

export const Certificate = ({
  product,
  showUserActions = false,
  qrPage = false,
  isLoading,
}: {
  product?: IProduct;
  showUserActions?: boolean;
  qrPage?: boolean;
  isLoading: boolean;
}) => {
  const growthbook = useGrowthBook();
  const location = useLocation();
  const isDesktop = useBigDesktopMediaQuery();

  const userId = useAppSelector(selectUserId);
  const isLoggedUser = useAppSelector(selectIsUserLoggedIn);
  const isWinner = selectProductIsWinner(product);

  const product3dMultimedia = selectProduct3dMultimedia(product);
  const product3dCertificateMultimedia =
    selectProduct3dCertificateMultimedia(product);
  const productGoalVideoMultimedia = selectProductGoalVideoMultimedia(product);
  const productTags = selectProductTags(product);
  const productEvent = selectCurrentGol(product);
  const productContract = selectProductContract(product);
  const productChainOfCustodyMultimedia =
    selectProductChainOfCustodyMultimedia(product);
  const productTacticMultimedia = selectProductTacticMultimedia(product);
  const tournament = selectProductMatchTournament(product);
  const competition = selectProductMatchCompetition(product);
  const stage = selectProductMatchStage(product);
  const goalClubId = selectProductGolClubId(product);
  const homeClub = selectProductMatchHomeClub(product);
  const awayClub = selectProductMatchAwayClub(product);
  const homeGoal = selectProductMatchHomeClub(product);
  const awayGoal = selectProductMatchAwayClub(product);
  const productBallName = selectBallName(product);
  const playerMultimedia = selectProductPlayerMultimedia(product);
  const carrouselMultimedia = selectProductCarrouselMultimedia(product);
  const contents = selectProductContent(product);
  const player = selectProductPlayer(product);
  const match = selectProductMatch(product);
  const isProductAuction = selectProductIsAuction(product);

  const { t } = useTranslation();
  const isTablet = useTabletMediaQuery();

  const [sponsorUsers, setSponsorUsers] = useState<string[]>([]);
  const [openPhoneDialog, setOpenPhoneDialog] = useState<boolean>(false);

  const [
    sendStickers,
    {
      isLoading: isLoadingSendStickers,
      isError: isErrorSendStickers,
      isSuccess: isSuccessSendStickers,
    },
  ] = useSendStickersMutation();

  const userNeedsPhoneValidation = useAppSelector(
    selectIsUserNeedsPhoneValidation,
  );

  const polygonScanLinkQuery = useGetPolygonscanLinkQuery(
    isLoggedUser && product
      ? {
          product_id: product?.product_id,
          piece_number: product?.last_piece_number,
        }
      : skipToken,
  );
  const transactionAddress = polygonScanLinkQuery.data?.[0];

  const existsSecondaryMultimedia = useMemo(
    () => productChainOfCustodyMultimedia || productTacticMultimedia,
    [productChainOfCustodyMultimedia, productTacticMultimedia],
  );

  useEffect(() => {
    const afterPaymentSuccess = location.state?.source === 'payment-success';
    setOpenPhoneDialog(userNeedsPhoneValidation && afterPaymentSuccess);
  }, [location.pathname, location.state?.source, userNeedsPhoneValidation]);

  const showAlert = useMemo(
    () => isErrorSendStickers || isSuccessSendStickers,
    [isErrorSendStickers, isSuccessSendStickers],
  );

  useEffect(() => {
    const users = growthbook?.getFeatureValue(SPONSOR_USERS, []);
    setSponsorUsers(users ?? []);
  }, [growthbook]);

  const eventImages = useMemo(() => {
    const images = [playerMultimedia?.location];
    const locationList = carrouselMultimedia.map((multimedia) =>
      multimedia?.location ? multimedia?.location : '',
    );

    return images.concat(locationList.flat());
  }, [playerMultimedia, carrouselMultimedia]);

  return (
    <Stack
      data-testid={`certificate-${product?.product_id}`}
      spacing={SpaceSizes.md}
      width={isTablet ? '100%' : '95%'}
    >
      {showAlert ? (
        <AlertCard
          content={
            isErrorSendStickers
              ? t('pages.collectionDetails.messages.sendStickers.error')
              : t('pages.collectionDetails.messages.sendStickers.success')
          }
          id="sendStickersStatus"
          severity={
            isErrorSendStickers ? AlertSeverity.ERROR : AlertSeverity.SUCCESS
          }
          show={showAlert}
        />
      ) : null}

      <CollectableMainWidget
        images={eventImages}
        isLoading={isLoading}
        rightChild={
          isDesktop && (
            <Stack gap={SpaceSizes.md}>
              {userId && sponsorUsers.includes(userId) && (
                <Panel>
                  {t('pages.product.collaboration')}
                  <ImageFallback
                    alt="movistar"
                    height={SpaceSizes.lg}
                    src={`${env.REACT_APP_STATIC_BASE_URL}marketplace/logos/brands/movistar/movistar-big.png`}
                    width="auto"
                  />
                </Panel>
              )}
              <Box p={SpaceSizes.sm}>
                <ProductStatsTableWidget
                  awayClub={awayClub}
                  competition={competition}
                  homeClub={homeClub}
                  match={match}
                  player={player}
                  productEvent={productEvent}
                  productType={product?.product_type}
                  stage={stage}
                  tournament={tournament}
                />
              </Box>
            </Stack>
          )
        }
        video={productGoalVideoMultimedia?.location}
      />

      <Grid
        alignItems="stretch"
        columnSpacing={SpaceSizes.sm}
        justifyContent="center"
        container
      >
        <Grid style={{ paddingLeft: 0, paddingRight: 8 }} xs={12} item>
          <Panel sx={{ alignItems: 'flex-start' }}>
            <Stack gap={SpaceSizes.xs} justifyContent="center" width="100%">
              <Stack flexDirection="row" justifyContent="space-between">
                <ResultTime
                  awayClub={awayClub}
                  awayGoal={!!awayGoal}
                  homeClub={homeClub}
                  homeGoal={!!homeGoal}
                  isGol={product?.product_type === 'GOAL'}
                  isLoading={isLoading}
                  isWinner={isWinner}
                  player={player}
                  productEvent={productEvent}
                  tags={productTags}
                />
                {(productContract?.contract_address || transactionAddress) &&
                  growthbook?.isOn(WEB3_FEATURES) && (
                    <CollectableAdditionalInfo
                      contractAddress={productContract?.contract_address}
                      transactionAddress={transactionAddress}
                    />
                  )}
              </Stack>

              {isProductAuction && (
                <AuctionDetail productId={product?.product_id} />
              )}
              {product && !isProductAuction && (
                <GolBallQuantity product={product} />
              )}
            </Stack>
          </Panel>
        </Grid>
      </Grid>

      {!qrPage && <TriviaDetails product={product} isOwner />}

      {product && (
        <UserActions
          isLoadingSendStickers={isLoadingSendStickers}
          isSuccessSendStickers={isSuccessSendStickers}
          product={product}
          sendStickers={sendStickers}
          showUserActions={showUserActions}
        />
      )}
      {!qrPage && product && <BookmarkAndShare product={product} isOwned />}

      <PhoneVerificationDialog
        handleClose={() => setOpenPhoneDialog(false)}
        open={openPhoneDialog}
      />

      {!isDesktop && (
        <>
          {userId && sponsorUsers.includes(userId) && (
            <Panel>
              {t('pages.product.collaboration')}
              <ImageFallback
                alt="movistar"
                height={SpaceSizes.lg}
                src={`${env.REACT_APP_STATIC_BASE_URL}marketplace/logos/brands/movistar/movistar-big.png`}
                width="auto"
              />
            </Panel>
          )}
          <Stack py={SpaceSizes.xs}>
            <ProductStatsTableWidget
              awayClub={awayClub}
              competition={competition}
              goalClubId={goalClubId}
              homeClub={homeClub}
              isLoading={isLoading}
              match={match}
              player={player}
              productEvent={productEvent}
              productType={product?.product_type}
              stage={stage}
              tournament={tournament}
            />
          </Stack>
        </>
      )}

      {product?.product_type === 'GOAL' && (
        <CertificatesTabs
          isLoading={isLoading}
          product3dCertificateMultimedia={product3dCertificateMultimedia}
          product3dMultimedia={product3dMultimedia}
          productBallId={productBallName}
        />
      )}
      {contents && <ProductContentWidget contents={contents} />}

      {existsSecondaryMultimedia && product?.product_type === 'GOAL' && (
        <ProductMediaWidget
          isLoading={isLoading}
          productChainOfCustodyMultimedia={productChainOfCustodyMultimedia}
          productTacticMultimedia={productTacticMultimedia}
        />
      )}
    </Stack>
  );
};
